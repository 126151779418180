import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Logo from '../Imagenes/Logo2.png'
import { AiOutlineMenu } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';
import Reloj from '../Componentes/Reloj'
import { BiLogOut } from 'react-icons/bi';
import Navbar from '../Componentes/Navbar';

const EditarEvento = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const { id } = useParams();
    const navigate = useNavigate();
    const [date, setDate] = useState(new Date());
    const [Evento, setEvento] = useState([]);

    useEffect(() => {
        const url = `https://to-do.techmaniatic.com/trello-tech-login/ApiEventos.php?id=${id}`;
        axios.get(url)
            .then((response) => {
                const infoEvento = response.data;
                setEvento(infoEvento);
            })
            .catch((error) => {
                console.error(error);
            });
        const timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    }, [id]);

    const tick = () => {
        setDate(new Date());
    };

    const handleClick = () => {
        navigate('/');
    };

    const handleClick01 = () => {
        navigate('/Eventos');
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Evita el envío por defecto del formulario

        // Obtiene los valores actualizados del formulario
        const titulo = event.target.elements[0].value;
        const descripcion = event.target.elements[1].value;

        // Realiza la solicitud PUT para actualizar el evento en el servidor
        const url = `https://to-do.techmaniatic.com/trello-tech-login/ApiEventos.php?id=${id}`;
        axios
            .put(url, {
                id: id,
                titulo: titulo,
                descripcion: descripcion,
                fecha: Evento[0].fecha, // Asegúrate de enviar la fecha actual sin cambios
            })
            .then((response) => {
                // Maneja la respuesta del servidor
                console.log(response.data);
                navigate('/Eventos');
            })
            .catch((error) => {
                console.error(error);
            });
    };


    return (
        <div className='container-uno'>
            <div className={
                isNavExpanded ? "bloque-1 expanded" : "bloque-1"
            }>
                <button className='btn-cerrar-sesion2 posicion' onClick={() => {setIsNavExpanded(!isNavExpanded)}}><AiOutlineClose /></button>
                <img src={Logo} className='Logo2' alt='logo admin techmaniatic' />
                <Navbar />
            </div>
            <div className='bloque-2'>
                <div className='header'>
                    <Reloj />
                    <button className='btn-cerrar-sesion' onClick={handleClick}><BiLogOut /> Cerrar sesion</button>
                    <button className='btn-cerrar-sesion2' onClick={() => {
                        setIsNavExpanded(!isNavExpanded)
                    }}><AiOutlineMenu /></button>
                </div>
                <div className='contenedor-editar-evento'>
                    <p className='titulo-proyecto'>Editar evento: {Evento[0] && Evento[0].titulo}</p>
                    <form className='form-evento' onSubmit={handleSubmit}>
                        <input type='text' className='form-control' defaultValue={Evento[0] && Evento[0].titulo} />
                        <input type='text' className='form-control' defaultValue={Evento[0] && Evento[0].descripcion} />
                        <button type='submit' className='btn-pago'>Guardar cambios</button>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default EditarEvento