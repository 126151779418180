import React, { useState, useEffect } from 'react'
import Logo from '../Imagenes/Logo2.png'
import Reloj from '../Componentes/Reloj'
import { BiLogOut } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Componentes/Navbar';
import TareasPorProyecto from '../Componentes/TareasPorProyecto';
import axios from 'axios';

const DetalleDeProyecto = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const { id } = useParams();
    const navigate = useNavigate();
    const [Proyecto, setProyecto] = useState([]);
    const [id_proyecto, setId_proyecto] = useState(id);

    useEffect(() => {
        setId_proyecto(id);

        const url3 = `https://to-do.techmaniatic.com/trello-tech-login/ApiTareas.php?id=${id}`;
        axios.get(url3)
            .then((response) => {
                const infoProyecto = response.data;
                setProyecto(infoProyecto);

            })
            .catch((error) => {
                console.error(error);
            });

    }, [id]);


    const handleClick = () => {
        navigate('/');
    };

    const [tarea, setTarea] = useState('');
    const [fecha, setFecha] = useState(new Date());
    const [completada, setCompletada] = useState('incompleta');


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://to-do.techmaniatic.com/trello-tech-login/ApiListaTareas.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id_proyecto: id_proyecto, tarea, fecha, completada }) // Aquí se convierte el objeto JSON a una cadena
            });

            const data = await response.json();
            console.log(data);

            if (data[0] === 'success') {
                navigate(0)
            }

        } catch (error) {
            console.error(error);
        }

    }

    return (
        <div className='container-uno'>
            <div className={
                isNavExpanded ? "bloque-1 expanded" : "bloque-1"
            }>
                <button className='btn-cerrar-sesion2 posicion' onClick={() => {setIsNavExpanded(!isNavExpanded)}}><AiOutlineClose /></button>
                <img src={Logo} className='Logo2' alt='logo admin techmaniatic' />
                <Navbar />
            </div>
            <div className='bloque-2'>
                <div className='header'>
                    <Reloj />
                    <button className='btn-cerrar-sesion' onClick={handleClick}><BiLogOut /> Cerrar sesion</button>
                    <button className='btn-cerrar-sesion2' onClick={() => {
                        setIsNavExpanded(!isNavExpanded)
                    }}><AiOutlineMenu /></button>
                </div>
                <div className='contenedor-facturacion1'>
                    <p className='titulo-proyecto'>Proyecto: <span>{Proyecto[0] && Proyecto[0].nombre_proyecto}</span></p>
                    <div className='Container-nuevo-proyecto1'>
                        <form className='form-tareas' onSubmit={handleSubmit}>
                            <input type='text'
                                className='form-control'
                                placeholder='Nueva Tarea...'
                                value={tarea}
                                onChange={(e) => setTarea(e.target.value)} />
                            <button className='btn-cerrar-sesion' type='submit'>+</button>
                        </form>
                    </div>
                    <div className='container-lista-tareas-proyecto'>
                        <TareasPorProyecto proyectoId={id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetalleDeProyecto