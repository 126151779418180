import React from 'react'
import { Link } from 'react-router-dom'
import { BiSolidDashboard } from 'react-icons/bi';
import { FaFileInvoice } from 'react-icons/fa';
import { BsCardList } from 'react-icons/bs';
import { BiMessageAltDots } from 'react-icons/bi';
import Calendario2 from './Calendario2'

const Navbar = () => {
    
    return (
        <div className='contenedor-navegacion'>
            <nav>
                <ul>
                    <li><Link to='/Inicio'><BiSolidDashboard />Inicio</Link></li>
                    <li><Link to='/Facturacion'><FaFileInvoice /> Generar Facturas</Link></li>
                    <li><Link to='/ListaDeTareas'><BsCardList /> ListaDeTareas</Link></li>
                    <li><Link to='/Eventos'><BsCardList /> Eventos</Link></li>
                    <li><Link to='/Contacto'><BiMessageAltDots /> Contacto</Link></li>
                </ul>
            </nav>
            <Calendario2/>
            <br/>
        </div>
    )
}

export default Navbar