import React, { useState, useEffect } from 'react'
import axios from "axios";
import Factura from './Factura';

const ListaPagos = () => {

    const [listPagos, setListPagos] = useState([]);
    const [showFactura, setShowFactura] = useState(false);
    const [pagoSeleccionado, setPagoSeleccionado] = useState([]);

    useEffect(() => {

        const url = 'https://to-do.techmaniatic.com/trello-tech-login/ApiFacturacion.php';
        axios.get(url)
            .then((response) => {
                const infoPagos = response.data;
                setListPagos(infoPagos);
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);


    return (
        <div className='table-info-pagos'>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th>Nombre</th>
                            <th>Cedula</th>
                            <th>Telefono</th>
                            <th>Descripcion</th>
                            <th>Valor producto y/o servicio</th>
                            <th>Tipo de pago</th>
                            <th>Valor Abono</th>
                            <th>Saldo a pagar</th>
                            <th>Observaciones</th>
                            <th>Fecha</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listPagos.map((lr) => (
                            <tr key={lr.id}>
                                <th scope="row">{lr.id}</th>
                                <td>{lr.nombre}</td>
                                <td>{lr.cedula}</td>
                                <td>{lr.telefono}</td>
                                <td>{lr.descripcion}</td>
                                <td>$ {lr.valorTotal}</td>
                                <td>{lr.tipoPago}</td>
                                <td>${lr.abono}</td>
                                <td>${lr.valorTotal - lr.abono}</td>
                                <td><p style={{width: '350px'}} >{lr.observaciones}</p></td>
                                <td>{lr.fecha}</td>
                                <td><button className='btn-factura' onClick={() => { setShowFactura(!showFactura); setPagoSeleccionado(lr); }}>Generar Factura</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Factura showFactura={showFactura} setShowFactura={setShowFactura} pagoSeleccionado={pagoSeleccionado} />
        </div>
    )
}

export default ListaPagos