import AppRouting from './Componentes/AppRouting';
import './App.css';

function App() {
  return (
    <div className="App">
      <AppRouting />
    </div>
  );
}

export default App;
