import React, { useState } from 'react'
import Logo from '../Imagenes/Logo2.png'
import Reloj from '../Componentes/Reloj'
import { BiLogOut } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import ItemCheckbox from '../Componentes/ItemCheckbox';

const InfoClientes = () => {

    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const items = ['Foto 360', 'Pagina web personalizada', 'Tour Virtual', 'E-commerce', 'chatbot', 'Generador de cotizaciones'];


    const [nombre, setNombre] = useState('');
    const [celular, setCelular] = useState('');
    const [what, setWhat] = useState('');
    const [nombre_empresa, setNombre_empresa] = useState('');
    const [nicho, setNicho] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [productoList, setProductoList] = useState([]);

    const handleCheckboxChange = (event, item) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            setProductoList((prevProductos) => [...prevProductos, item]);
        } else {
            setProductoList((prevProductos) =>
                prevProductos.filter((productoList) => productoList !== item)
            );
        }
    };

    
    const navigate = useNavigate();

    const addEvent = async (e) => {
        e.preventDefault();
        const producto = productoList.join(', ');
        try {
            const response = await fetch('https://to-do.techmaniatic.com/trello-tech-login/ApiClientes.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ nombre, celular, what, nombre_empresa, nicho, producto, observaciones }) 
            });

            const data = await response.json();
            console.log("Data: ",data)
            console.log(producto)
            // if (data.mensaje === 'Cliente agregado exitosamente.') {
            //     navigate(0)              
            // }

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='container-uno'>
            <div className={
                isNavExpanded ? "bloque-1 expanded" : "bloque-1"
            }>
                <button className='btn-cerrar-sesion2 posicion' onClick={() => { setIsNavExpanded(!isNavExpanded) }}><AiOutlineClose /></button>
                <img src={Logo} className='Logo2' alt='logo admin techmaniatic' />
            </div>
            <div className='bloque-2'>
                <div className='header'>
                    <Reloj />
                    <button className='btn-cerrar-sesion2' onClick={() => { setIsNavExpanded(!isNavExpanded) }}><AiOutlineMenu /></button>
                </div>
                <div className='contenedor-editar-evento'>
                    <form className='form-evento' onSubmit={addEvent}>
                        <input type='text' className='form-control' placeholder='Nombre del cliente'
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)} />
                        <input type='text' className='form-control' placeholder='Celular'
                            value={celular}
                            onChange={(e) => setCelular(e.target.value)} />

                        <div className='container-check'>
                            <p>Este numero de Whatssapp: </p>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                    value="si"
                                    onChange={(e) => setWhat(e.target.value)}
                                    checked={what === 'si'}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">Si</label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    value="no"
                                    onChange={(e) => setWhat(e.target.value)}
                                    checked={what === 'no'}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">No</label>
                            </div>
                        </div>


                        <input type='text' className='form-control' placeholder='Nombre de la empresa'
                            value={nombre_empresa}
                            onChange={(e) => setNombre_empresa(e.target.value)} />
                        <input type='text' className='form-control' placeholder='Nicho de la empresa'
                            value={nicho}
                            onChange={(e) => setNicho(e.target.value)} />
                        <p className='texto-form'>Que producto te interesaria de techmaniatic: </p>
                        {items.map((item, index) => (
                            <ItemCheckbox
                                key={index}
                                item={item}
                                checked={productoList.includes(item)}
                                onChange={(event) => handleCheckboxChange(event, item)}
                            />
                        ))}
                        <p className='texto-form'>Observaciones:  </p>
                        <div className="form-floating">
                            <textarea className="form-control" placeholder="Observaciones"
                                id="floatingTextarea"
                                value={observaciones}
                                onChange={(e) => setObservaciones(e.target.value)}></textarea>
                            <label for="floatingTextarea">Observaciones</label>
                        </div>
                        <button type='submit' className='btn-pago'>Guardar</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default InfoClientes