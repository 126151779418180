import React, { useState } from 'react'
import Logo from '../Imagenes/Logo2.png'
import Reloj from '../Componentes/Reloj'
import { BiLogOut } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert2'
import Navbar from '../Componentes/Navbar';
import { AiOutlineClose } from 'react-icons/ai';
import ListaPagos from '../Componentes/ListaPagos';

const Facturacion = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const [showNuevoPago, setShowNuevoPago] = useState(false);

    const handleChange = (e) => {
        setTipoPago(e.target.value);
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    };

    /*****************************************************************/

    const [nombre, setNombre] = useState('');
    const [cedula, setCedula] = useState();
    const [telefono, setTelefono] = useState();
    const [descripcion, setDescription] = useState('');
    const [valorTotal, setValorTotal] = useState();
    const [tipoPago, setTipoPago] = useState();
    const [abono, setAbono] = useState();
    const [observaciones, setObservaciones] = useState('');

    const fecha = new Date();
    const añoActual = fecha.getFullYear();
    const mesActual = fecha.getMonth() + 1;
    const diaActual = fecha.getDate()
    const fechaCompleta = añoActual + "/" + mesActual + "/" + diaActual;

    const handleSubmit = (event) => {
        event.preventDefault();
        const url = 'https://to-do.techmaniatic.com/trello-tech-login/ApiFacturacion.php';
        axios
            .post(url, {
                nombre: nombre,
                cedula: cedula,
                telefono: telefono,
                descripcion: descripcion,
                valorTotal: valorTotal,
                tipoPago: tipoPago,
                abono: abono,
                observaciones: observaciones,
                fecha: fechaCompleta,// Asegúrate de enviar la fecha actual sin cambios
            })
            .then((response) => {
                // Maneja la respuesta del servidor
                console.log(response.data);
                swal.fire('Registro insertado correctamente', response.data, 'success')
                navigate(0)
            })
            .catch((error) => {
                console.error(error);
            });
    };



    return (
        <div className='container-uno'>
            <div className={
                isNavExpanded ? "bloque-1 expanded" : "bloque-1"
            }>
                <button className='btn-cerrar-sesion2 posicion' onClick={() => {setIsNavExpanded(!isNavExpanded)}}><AiOutlineClose /></button>
                <img src={Logo} className='Logo2' alt='logo admin techmaniatic' />
                <Navbar />
            </div>
            <div className='bloque-2'>
                <div className='header'>
                    <Reloj />
                    <button className='btn-cerrar-sesion' onClick={handleClick}><BiLogOut /> Cerrar sesion</button>
                    <button className='btn-cerrar-sesion2' onClick={() => {
                        setIsNavExpanded(!isNavExpanded)
                    }}><AiOutlineMenu /></button>
                </div>
                <div className='contenedor-facturacion'>
                    <p className='titulo3'>Facturacion TECHMANIATIC</p>
                    <button className='btn-pago' onClick={() => { setShowNuevoPago(!showNuevoPago); }}>Insertar Pago</button>
                    <div className={showNuevoPago ? "contenedor-nuevo-pago show" : "contenedor-nuevo-pago"}>
                        <div className='btn-pago1-1'>
                            <button className='btn-pago1' onClick={() => { setShowNuevoPago(!showNuevoPago); }}><AiOutlineClose /></button>
                        </div>
                        <p className='titulo3'>Insertar nuevo pago</p>
                        <form className='formulario-pago' onSubmit={handleSubmit}>

                            <div className='contenedor-inputs'>
                                <input type='text' className='form-control' placeholder='Nombre del cliente'
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)} />
                                <input type='number' className='form-control' placeholder='Cedula del cliente'
                                    value={cedula}
                                    onChange={(e) => setCedula(e.target.value)} />
                                <input type='number' className='form-control' placeholder='Telefono del cliente'
                                    value={telefono}
                                    onChange={(e) => setTelefono(e.target.value)} />
                                <input type='text' className='form-control' placeholder='Descripcion del servicio y/o producto'
                                    value={descripcion}
                                    onChange={(e) => setDescription(e.target.value)} />
                                <input type='number' className='form-control' placeholder='Valor total del servicio y/o producto '
                                    value={valorTotal}
                                    onChange={(e) => setValorTotal(e.target.value)} />
                                <select className="form-select" value={tipoPago} onChange={handleChange}>
                                    <option value="">Selecciona un tipo de pago</option>
                                    <option value="Pago total">Pago total</option>
                                    <option value="Abono">Abono</option>
                                </select>
                                {tipoPago === "Abono" && (
                                    <div className="div-oculto">
                                        <input type='number' className='form-control' placeholder='Valor abono'
                                            value={abono}
                                            onChange={(e) => setAbono(e.target.value)} />
                                    </div>
                                )}
                                <input type='text' className='form-control' placeholder='Observaciones generales'
                                    value={observaciones}
                                    onChange={(e) => setObservaciones(e.target.value)} />
                            </div>
                            <button className='btn-pago' type='submit'>Guardar</button>
                        </form>

                    </div>
                </div>
                <ListaPagos />

            </div>
        </div>
    )
}

export default Facturacion