import React, { useEffect, useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai';

const Reloj = () => {
    const [date, setDate] = useState(new Date());

    const tick = () => {
        setDate(new Date());
    };

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    }, []);

    return (
        <div className='contenedor-reloj'>
            <AiOutlineClockCircle />
            <div>
                <p>{date.toLocaleTimeString()}</p>
            </div>
        </div>
    )
}

export default Reloj