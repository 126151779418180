import React, { useState } from 'react'
import Logo from '../Imagenes/Logo2.png'
import Reloj from '../Componentes/Reloj'
import { BiLogOut } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Componentes/Navbar';
import FotoGrupo from '../Imagenes/FotoGrupo1.jpeg'

const Inicio = () => {

  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <div className='container-uno'>
      <div className={
        isNavExpanded ? "bloque-1 expanded" : "bloque-1"
      }>
        <button className='btn-cerrar-sesion2 posicion' onClick={() => {setIsNavExpanded(!isNavExpanded)}}><AiOutlineClose /></button>
        <img src={Logo} className='Logo2' alt='logo admin techmaniatic' />
        <Navbar />
      </div>
      <div className='bloque-2'>
        <div className='header'>
          <Reloj />
          <button className='btn-cerrar-sesion' onClick={handleClick}><BiLogOut /> Cerrar sesion</button>
          <button className='btn-cerrar-sesion2' onClick={() => {setIsNavExpanded(!isNavExpanded)}}><AiOutlineMenu /></button>
        </div>
        <img src={FotoGrupo} alt="" className='img-grupo' />
      </div>
    </div>
  )
}

export default Inicio