import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const NuevoEvento = () => {

    const navigate = useNavigate();
    const [value, setValue] = useState("");
    const [value1, setValue1] = useState("");
    const Dia = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"];
    const Mes = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const captureType = (e) => {
        setValue(e.target.value);
        // Muestra el valor seleccionado por consola
        console.log(e.target.value);
    }

    const captureType1 = (e) => {
        setValue1(e.target.value);
        // Muestra el valor seleccionado por consola
        console.log(e.target.value);
    }

    const fechaCompleta = value + "/" + value1 + "/2023";
    const [eventTitle, setEventTitle] = useState('');
    const [eventDescription, setEventDescription] = useState('');

    const addEvent = () => {
        // Crear objeto de evento con los datos del formulario
        const event = {
            titulo: eventTitle,
            descripcion: eventDescription,
            fecha: fechaCompleta // Convertir fecha a formato ISO para almacenamiento
        };
        // Enviar evento a tu API de PHP y MySQL para almacenarlo en la base de datos
        fetch('https://to-do.techmaniatic.com/trello-tech-login/ApiEventos.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(event)
        })
            .then(response => response.json())
            .then(data => {
                setEventTitle('');
                setEventDescription('');
                navigate(0)
            })
            .catch(error => {
                console.error('Error al agregar el evento:', error);
            });
    };

    return (
        <div className='Container-nuevo-proyecto'>
            <p className='title-nuevo-proyecto'>Nuevo evento</p>
            <p>Escoja el dia y el mes de  la fecha de evento a ingresar:</p>
            <div className='container-nuevo-fecha'>
                <p>Año 2023</p>
                <select className="form-select" value={value} onChange={captureType}>
                    {Dia.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <select className="form-select" value={value1} onChange={captureType1}>
                    {Mes.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <p>La fecha seleccionada es {fechaCompleta}</p>
            <div className='form-evento'>
                <input
                    type='text'
                    placeholder='Titulo'
                    className='form-control'
                    value={eventTitle}
                    onChange={(e) => setEventTitle(e.target.value)} />
                <input type='text'
                    placeholder='Descripcion'
                    className='form-control'
                    value={eventDescription}
                    onChange={(e) => setEventDescription(e.target.value)} />
                <button className='btn-cerrar-sesion1' onClick={() => addEvent()}>Guardar evento</button>
            </div>
        </div>
    )
}

export default NuevoEvento