import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';

const ListaDeProyectos = () => {
    const [listProtectos, setListProyectos] = useState([]);

    useEffect(() => {

        const url3 = 'https://to-do.techmaniatic.com/trello-tech-login/ApiTareas.php';
        axios.get(url3)
            .then((response) => {
                const tareasProyecto = response.data;
                setListProyectos(tareasProyecto);
                console.log("Tareas: " + tareasProyecto);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);


    return (
        <div className='container-proyectos'>
            {listProtectos.map((lt) => {
                const estiloProyecto = {
                    backgroundColor: lt.codigo_color
                };
                return (
                    <Link to={`/Proyecto/${lt.id}`} key={lt.id}>
                        <div className='cont-proyecto' style={estiloProyecto}>
                            <p className='fech_tarea'>{lt.nombre_proyecto}</p>
                        </div>
                    </Link>
                );
            })}
        </div>
    )
}

export default ListaDeProyectos