import React from 'react'
import Logo from '../Imagenes/Logo.png'
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";

const FacturaPdf = (props) => {
    const styles = StyleSheet.create({
        table: { display: 'flex', flexDirection: 'column', width: '100%', },
        row: { display: 'flex', flexDirection: 'row', width: '100%', },
        cell: {
            flex: 1,
            padding: 5,
            borderWidth: 1,
            borderColor: '#dee2e6',
            fontSize: '14px',
            color: "#7f8387"
        },
    });

    return (
        <Document className={props.showFactura ? "contenedor-factura show" : "contenedor-factura"}>
            <Page
                size="A4"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 30,
                }}>
                <View
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        width: '100%',
                        padding: 20,
                        backgroundColor: "#B5B5B5"
                    }}>
                    <Text style={{ fontSize: "15px", color: "#FFFFFF" }}>Fecha: {props.pagoSeleccionado.fecha} Nombre: {props.pagoSeleccionado.nombre}</Text>

                </View>
                <View style={styles.table}>
                    <View style={styles.row}>
                        <Text style={styles.cell}>
                            Cedula
                        </Text>
                        <Text style={styles.cell}>
                            {props.pagoSeleccionado.cedula}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.cell}>
                            Telefono
                        </Text>
                        <Text style={styles.cell}>
                            {props.pagoSeleccionado.telefono}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.cell}>
                            Descripcion del servicio:
                        </Text>
                        <Text style={styles.cell}>
                            {props.pagoSeleccionado.descripcion}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.cell}>
                            Vendedor:
                        </Text>
                        <Text style={styles.cell}>
                            TECHMANIATIC
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.cell}>
                            Valor total del producto y/o servicio
                        </Text>
                        <Text style={styles.cell}>
                            $ {props.pagoSeleccionado.valorTotal}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.cell}>
                            Tipo de pago
                        </Text>
                        <Text style={styles.cell}>
                            {props.pagoSeleccionado.tipoPago}
                        </Text>
                    </View>
                    {props.pagoSeleccionado.tipoPago === 'Abono'
                        ?
                        <>
                            <View style={styles.row}>
                                <Text style={styles.cell}>
                                    Valor del Abono
                                </Text>
                                <Text style={styles.cell}>
                                    $ {props.pagoSeleccionado.abono}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cell}>
                                    Saldo restante
                                </Text>
                                <Text style={styles.cell}>
                                    $ {props.pagoSeleccionado.valorTotal - props.pagoSeleccionado.abono}
                                </Text>
                            </View>
                        </>
                        :
                        <Text ></Text>
                    }

                    <View style={styles.row}>
                        <Text style={styles.cell}>
                            Observaciones generales
                        </Text>
                        <Text style={styles.cell}>
                            {props.pagoSeleccionado.observaciones}
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default FacturaPdf