import React,{useState} from 'react'
import Logo from '../Imagenes/Logo2.png'
import Reloj from '../Componentes/Reloj'
import { BiLogOut } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Componentes/Navbar';
import { AiOutlineLink } from 'react-icons/ai';

const Contacto = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    };

    return (
        <div className='container-uno'>
            <div className={
                isNavExpanded ? "bloque-1 expanded" : "bloque-1"
            }>
                <button className='btn-cerrar-sesion2 posicion' onClick={() => {setIsNavExpanded(!isNavExpanded)}}><AiOutlineClose /></button>
                <img src={Logo} className='Logo2' alt='logo admin techmaniatic' />
                <Navbar />
            </div>
            <div className='bloque-2'>
                <div className='header'>
                    <Reloj />
                    <button className='btn-cerrar-sesion' onClick={handleClick}><BiLogOut /> Cerrar sesion</button>
                    <button className='btn-cerrar-sesion2' onClick={() => {
                        setIsNavExpanded(!isNavExpanded)
                    }}><AiOutlineMenu /></button>
                </div>
                <div className='contenedor-facturacion'>
                    <p className='titulo3'>Contacto</p>
                    <div className='grid-container-contactenos'>
                        <div className='item1'>
                            <p className='titulo1-gcc'>Creador de esta aplicacion:</p>

                            <div className='grid-tech'>
                                <div className='grid-tech-item'>
                                    <img src={Logo} alt='Logo' className='img-contacto' />
                                </div>
                                <div className='grid-tech-item1'>
                                    <a href='https://techmaniatic.com/' className='btn-pago'>Ver Pagina</a>
                                </div>
                            </div>
                            <br />
                            <a className='LinkTree' href='https://linktr.ee/techmaniatic' target='_blank'><AiOutlineLink /> linktr.ee/techmaniatic</a>
                            {/* <ul className='menu-tech'>
                                <li>contacto@techmaniatic.com</li>
                                <li><AiFillPhone /> +57 300 536 7192 / +57 314 679 2843</li>
                                <li><AiOutlineInstagram /> @techmaniatic</li>
                                <li><CiLocationOn /> Viterbo Caldas, Colombia</li>
                            </ul> */}

                        </div>
                        <div className='item2'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15897.063921699131!2d-75.8811107302246!3d5.0605779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e478537250ec0cf%3A0x15a105e281e5bed2!2sViterbo%2C%20Caldas!5e0!3m2!1ses!2sco!4v1687964778888!5m2!1ses!2sco" allowfullscreen="" loading="lazy" className='mapa' referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacto