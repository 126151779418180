import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Inicio from '../Vistas/Inicio'
import Login from '../Vistas/Login'
import Facturacion from '../Vistas/Facturacion'
import ListaDeTareas from '../Vistas/ListaDeTareas'
import DetalleDeProyecto from '../Vistas/DetalleDeProyecto'
import Eventos from '../Vistas/Eventos'
import EditarEvento from './EditarEvento'
import Contacto from '../Vistas/Contacto'
import InfoClientes from '../Vistas/InfoClientes'

const AppRouting = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/Inicio' element={<Inicio />} />
                    <Route path='/Contacto' element={<Contacto />} />
                    <Route path='/Facturacion' element={<Facturacion />} />
                    <Route path='/InfoClientes' element={<InfoClientes />} />
                    <Route path='/ListaDeTareas' element={<ListaDeTareas />} />
                    <Route path="/Proyecto/:id" element={<DetalleDeProyecto />}  />
                    <Route path="/EditarEvento/:id" element={<EditarEvento />}  />
                    <Route path='/Eventos' element={<Eventos />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default AppRouting