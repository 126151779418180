import React from 'react';

function ItemCheckbox({ item, checked, onChange }) {
  return (
    <label className='label-check'>
      <input
        type="checkbox"
        value={item}
        checked={checked}
        onChange={onChange}
        className="form-check-input"
      />
      {item}
    </label>
  );
}

export default ItemCheckbox;