import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import EditarEvento from './EditarEvento';

const ListaDeEvento = () => {
    const navigate = useNavigate();
    const [listEventos, setListEventos] = useState([]);
    const [showNuevoPago, setShowNuevoPago] = useState(false);

    useEffect(() => {

        const url = 'https://to-do.techmaniatic.com/trello-tech-login/ApiEventos.php';
        axios.get(url)
            .then((response) => {
                const infoEvento = response.data;
                setListEventos(infoEvento);
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);

    const handleEliminarEvento = (id) => {
        const url1 = `https://to-do.techmaniatic.com/trello-tech-login/ApiEventos.php?id=${id}`;
        axios.delete(url1)
            .then((response) => {
                console.log("Evento eliminada: " + response.data);
                navigate(0)
            })
            .catch((error) => {
                console.error(error);
                // Realizar las acciones necesarias en caso de error
            });
    };

    return (
        <div className='Contenedor-lista-eventos'>
            <div className='grid-eventos'>
                {listEventos.map((event) => (
                    <>
                        <div className='card-eventos'>
                            <p>Fecha: <span>{event.fecha}</span></p>
                            <p>Titulo: <span>{event.titulo}</span></p>
                            <p>{event.descripcion}</p>
                            <div className='acciones-evento'>
                                <button className='btn3'><Link to={`/EditarEvento/${event.id}`}>Editar</Link></button>
                                <button className='btn4'
                                    onClick={() => handleEliminarEvento(event.id)}>
                                    <AiFillDelete />
                                </button>
                            </div>
                        </div>
                    </>
                ))}
            </div>

        </div>
    )
}

export default ListaDeEvento