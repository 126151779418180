import React, { useState, useRef } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Logo from '../Imagenes/Logo.png'
import FacturaPdf from './FacturaPdf';
import { PDFDownloadLink } from "@react-pdf/renderer";

const Factura = (props) => {
    return (
        <div className={props.showFactura ? "contenedor-factura show" : "contenedor-factura"}>
            <div className='btn-pago1-1'>
                <button className='btn-pago1' onClick={() => { props.setShowFactura(!props.showFactura); }}><AiOutlineClose /></button>
            </div>
            <div className="EstiloRecbo">
                <div className="HeaderRecibo" style={{ backgroundColor: '#B5B5B5', padding: "10px" }}>
                    <img src={Logo} alt="Logo" className="logoRecibo" />
                    <div>
                        <p className="fechaRecibo">Fecha: {props.pagoSeleccionado.fecha}</p>
                        <p className="fechaRecibo"> <span> {props.pagoSeleccionado.nombre}</span></p>
                    </div>
                </div>
                <div className="BodyRecibo">
                    <table className="tablaRecibo">
                        <tbody>
                            <tr>
                                <td> Cedula  </td>
                                <td> {props.pagoSeleccionado.cedula}  </td>
                            </tr>
                            <tr>
                                <td> Telefono  </td>
                                <td> {props.pagoSeleccionado.telefono}  </td>
                            </tr>
                            <tr>
                                <td> Descripcion del servicio:   </td>
                                <td> {props.pagoSeleccionado.descripcion}  </td>
                            </tr>
                            <tr>
                                <td> Vendedor:   </td>
                                <td> TECHMANIATIC  </td>
                            </tr>
                            <tr>
                                <td> Valor total del producto y/o servicio  </td>
                                <td> $ {props.pagoSeleccionado.valorTotal}  </td>
                            </tr>
                            <tr>
                                <td> Tipo de pago  </td>
                                <td> {props.pagoSeleccionado.tipoPago}  </td>
                            </tr>
                            {props.pagoSeleccionado.tipoPago === 'Abono'
                                ?
                                <>
                                    <tr>
                                        <td> Valor del Abono   </td>
                                        <td> $ {props.pagoSeleccionado.abono}  </td>
                                    </tr>
                                    <tr>
                                        <td> Saldo restante  </td>
                                        <td> $ {props.pagoSeleccionado.valorTotal - props.pagoSeleccionado.abono}  </td>
                                    </tr>
                                </>
                                :
                                <p></p>
                            }
                            <tr>
                                <td> Observaciones generales  </td>
                                <td> {props.pagoSeleccionado.observaciones}  </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br/>
            <PDFDownloadLink document={<FacturaPdf showFactura={props.showFactura} setShowFactura={props.setShowFactura} pagoSeleccionado={props.pagoSeleccionado} />} fileName='Recibo Techmaniatic'>
                <button className='btn-factura'>Descargar factura</button>
            </PDFDownloadLink>
        </div>
    )
}

export default Factura